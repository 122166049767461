<template>
  <div
    class="uk-card uk-margin-top uk-card-default uk-card-body uk-text-left"
    style="box-shadow: none;"
  >
    <h4>
      1.99.4
      <small>
        (2024-12-30)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Улучшения в поддержки медии модуля Twitter
      </li>
    </ul>
    <h4>
      1.99.3
      <small>
        (2024-11-29)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.99.2
      <small>
        (2024-10-28)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Экстрактор метаданных для вложений
      </li>
      <li>
        Теперь предыдущие владельцы Telegram чатов/каналов, отображаются нормально.
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.99.1
      <small>
        (2024-09-16)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Поддержка дополнительных спецсимволов в эксорте кейсов в PDF
      </li>
      <li>
        Поддержка упоминаний в модуле Discord
      </li>
      <li>
        Добавлена ссылка на создателя invite ссылки в модуле Discord
      </li>
      <li>
        Добавлена опция для скрытия NSFW форумов под блюром
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.99.0
      <small>
        (2024-08-30)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Модуль Discord: Страницы сообщений, пользователей, каналов
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.98.7
      <small>
        (2024-07-30)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.98.6
      <small>
        (2024-06-18)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.98.5
      <small>
        (2024-06-07)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Поддержка тем чатов в модуле Telegram
      </li>
      <li>
        Улучшение UX модуля форумов
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.98.4
      <small>
        (2024-04-29)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлен поиск по спискам подписчиков в модуле Twitter'а
      </li>
      <li>
        Информационные поля в товарах в модуле Форумов отображаются правильно.
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.98.3
      <small>
        (2024-04-17)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.98.2
      <small>
        (2024-04-12)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.98.1
      <small>
        (2024-03-26)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.98.0
      <small>
        (2024-03-22)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлен модуль IMO
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.97.5
      <small>
        (2024-02-03)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Фильтры по дате для графиков "активность в чатах" и "самые активные юзеры"
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.97.4
      <small>
        (2024-01-27)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        При ресинке чатов пользователя при наличие юзернейма теперь создается дополнительный таск на ресинк юзера.
      </li>
      <li>
        Теперь ситуация когда у Telegram чата/канала несколько владельцев отображается правильно
      </li>
      <li>
        Новые настройки для веб источников
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.97.3
      <small>
        (2023-12-20)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлена функция поиска похожих изображений
      </li>
      <li>
        Добавлен флаг "Архивный" для элементов в глобальном поиске
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.97.2
      <small>
        (2023-11-17)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлен флаг "Фейк" для чатов/каналов в Telegram
      </li>
      <li>
        Улучшения экспорта
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.97.1
      <small>
        (2023-10-16)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.97.0
      <small>
        (2023-10-01)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлен эксплорер блокчейна Ton
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.96.3
      <small>
        (2023-09-30)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлен переход на гильдию из инвайт ссылок Discord
      </li>
      <li>
        Добавлены действия в попап по нажатию на Whatsapp ссылки
      </li>
      <li>
        Добавлена поддержка /s/ ссылок в модуль Telegram
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.96.2
      <small>
        (2023-08-31)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлен модуль Discord (Информация о гильдиях)
      </li>
      <li>
        Множественные улучшение для модуля Форумов
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.96.1
      <small>
        (2023-08-16)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Поддержка премиум emojis в модуле Telegram
      </li>
      <li>
        Внешние ссылки на сообщения в модуле форумов
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.96.0
      <small>
        (2023-07-30)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлен график активности пользователя в модулях форумов
      </li>
      <li>
        Добавлена поддержка флагов (Поддержка, Скам, Премиум, Верифицированный) для пользователей в модуле Telegram
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.95.1
      <small>
        (2023-06-29)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.95.0
      <small>
        (2023-06-01)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Во время экспорта можно задавать имя файла
      </li>
      <li>
        Поддержка кастомных анимированных эмодзи в модуле Telegram
      </li>
      <li>
        Кнопка для ресинка всех чатов, в которых состоял пользователь
      </li>
      <li>
        Лимит ботов теперь отображается в соответствия с лимитами пользователя
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.94.4
      <small>
        (2023-05-22)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Страница со статусами обновления форумов.
      </li>
      <li>
        Связанные чаты теперь корректно отображаются в истории (Модуль Telegram)
      </li>
      <li>
        Отображение превью .webp изображение в модуле Telegram
      </li>
      <li>
        Оптимизация операций экспорта (Модуль Telegram)
      </li>
      <li>
        Оптимизация итоговой сборки
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.94.3
      <small>
        (2023-04-15)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.94.2
      <small>
        (2023-03-22)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавленные дополнительные фильтры для задач Telegram
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.94.1
      <small>
        (2023-02-28)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        В модуле Форумов добавлены внешние ссылки
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.94.0
      <small>
        (2023-02-17)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Модуль TikTok
      </li>
      <li>
        Улучшение интерфейса информации о TON Кошельке (NFT, Jettons)
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.93.1
      <small>
        (2023-01-30)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Информация о Telegram username'ах в TON кошельке
      </li>
      <li>
        Улучшение интерфейса информации о TON Кошельке
      </li>
      <li>
        Информация о истории синхронизации задач Telegram
      </li>
      <li>
        Подсказка при поиске по id в каналах и пользователях Telegram
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.93.0
      <small>
        (2022-12-30)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлена поддержка виртуальных телефонных номеров TON
      </li>
      <li>
        Экспорт профилей в кейсе теперь происходит client side
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.92.2
      <small>
        (2022-11-24)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Теперь возможно переход на канал кликом на метки в графике, а не на сам график
      </li>
      <li>
        Добавлены вкладки с информацией о бывших пользователях групп Whatsapp
      </li>
      <li>
        Добавлена статистика по собранным странам в модуль  Whatsapp
      </li>
      <li>
        Информация о связанных сущностях на детальной странице пользователей Twitter
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.92.1
      <small>
        (2022-10-27)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Информация о связанных каналах на детальной странице пользователей Telegram
      </li>
      <li>
        Исправлена ошибка с отображением медии в модуле Whatsapp в Safari
      </li>
      <li>
        Ссылки теперь подсвечиваются корректно, в описаниях чатов Whatsapp
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.92.0
      <small>
        (2022-10-07)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Исправлена ошибка с поиском по некоторым unicode символам в Safari
      </li>
      <li>
        Добавлена кнопка в меню изображений для быстрого отправления изображения в поиск по лицам.
      </li>
      <li>
        Улучшения UX списка тасков модуля Whatsapp
      </li>
      <li>
        Расширенное меню при клике на Twitter ссылки
      </li>
      <li>
        На детальной странице пользователя в модуле Hoop'а теперь можно искать по сообщениям
      </li>
      <li>
        Множественные исправления UI и UX
      </li>
    </ul>
    <h4>
      1.91.3
      <small>
        (2022-09-13)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Небольшие улучшения UI
      </li>
    </ul>
    <h4>
      1.91.2
      <small>
        (2022-09-07)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Закрытые Twitter аккаунты теперь имеют соответствующую пометку
      </li>
      <li>
        Поддержка новых Telegram ссылок (username.t.me)
      </li>
      <li>
        Исправлен баг с иногда не показывающимся стикерами в модуле Whatsapp
      </li>
      <li>
        При отправке в сохраненное, Telegram каналы теперь отправляются в нужную категорию
      </li>
      <li>
        Множественное исправление багов или элементов UI
      </li>
    </ul>
    <h4>
      1.91.1
      <small>
        (2022-08-31)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Telegram каналы с ограничениями теперь имеют соответствующую пометку
      </li>
      <li>
        Раздел "Организации" был удален из меню
      </li>
      <li>
        Добавлен выбор лица с фотографии при поиске фотографий со множеством лиц
      </li>
      <li>
        Улучшения UI пакетной загрузки Whatsapp задач
      </li>
      <li>
        Множественное исправление ошибок
      </li>
    </ul>
    <h4>
      1.91.0
      <small>
        (2022-08-12)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Теги каналов теперь отображаются на детальной страницу в модуле Hoop
      </li>
      <li>
        Функционал поиска по распознаным лицам на ограниченной базе данных
      </li>
      <li>
        Ссылка на группу обсуждения добавлена на детальную страницу каналов в Telegram
      </li>
      <li>
        Расширенный диалог с доступными действиями при клике на Whatsapp ссылки
      </li>
      <li>
        Множественное исправление ошибок
      </li>
    </ul>
    <h4>
      1.90.4
      <small>
        (2022-07-30)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Вкладка "Известные ссылки" в группах Whatsapp
      </li>
      <li>
        Некоторые улучшение UX
      </li>
      <li>
        Множественное исправление ошибок
      </li>
    </ul>
    <h4>
      1.90.3
      <small>
        (2022-07-24)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Ссылка "Перейти к сообщению" в модуле Whatsapp
      </li>
      <li>
        Более детализированные статусы задач в модуле Whatsapp
      </li>
      <li>
        Улучшения в задачах модуля Hoop'а
      </li>
      <li>
        Изменена дефолтная сортировка сообщений в модуле Hoop
      </li>
      <li>
        Изменение поведения "Искать по ID" в модуле Telegram
      </li>
      <li>
        Улучшение отображение сообщений каналов в Discuss группах Telegram
      </li>
      <li>
        Авто сбор discuss групп при парсе канала, если у группы нет username'а в модуле Telegram
      </li>
      <li>
        Доработки UI связей
      </li>
      <li>
        Множественное исправление ошибок
      </li>
    </ul>
    <h4>
      1.90.2
      <small>
        (2022-07-03)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Доработки UI связей
      </li>
      <li>
        Множественное исправление ошибок
      </li>
    </ul>
    <h4>
      1.90.1
      <small>
        (2022-06-27)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Множественные аватары пользователей и групп Whatsapp теперь корректно отображаются
      </li>
      <li>
        Множественное исправление ошибок
      </li>
    </ul>
    <h4>
      1.90.0
      <small>
        (2022-06-18)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Медия собираемая модулем Whatsapp теперь корректно отображается
      </li>
      <li>
        Множественные улучшение UX Whatsapp модуля
      </li>
      <li>
        Множественное исправление ошибок
      </li>
    </ul>
    <h4>
      1.89.4
      <small>
        (2022-06-09)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Множественное исправление ошибок
      </li>
    </ul>
    <h4>
      1.89.3
      <small>
        (2022-06-05)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Фильтр пользователей по стране в модуле Hoop
      </li>
      <li>
        Возможность ставить задачи по сбору в модуле Hoop
      </li>
      <li>
        Вкладка с информацией о связях каналов/чатов Telegram на основе распространения Joinlink'ов
      </li>
      <li>
        Унифицированный UI интерфейс информации об изображениях
      </li>
      <li>
        Множественное исправление ошибок
      </li>
    </ul>
    <h4>
      1.89.2
      <small>
        (2022-05-19)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Статистика по юзерам форумам теперь отображается в списке юзеров
      </li>
      <li>
        Множественное исправление ошибок
      </li>
    </ul>
    <h4>
      1.89.1
      <small>
        (2022-04-20)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлен список основных источкников пересылки для Telegram каналов/чатов
      </li>
      <li>
        Множественное исправление ошибок
      </li>
    </ul>
    <h4>
      1.89.0
      <small>
        (2022-03-31)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Можно указывать поля для поиска при фильтрации юзеров форума
      </li>
      <li>
        Отображается история изменения данных пользователей форумов
      </li>
      <li>
        Отображается история изменения данных пользователей Twitter'а
      </li>
      <li>
        Можно использовать ID на форуме для поиска и фильтрации пользователей
      </li>
      <li>
        Множестевенные аватары для пользователей Twitter'а
      </li>
      <li>
        Множественное исправление ошибок
      </li>
    </ul>
    <h4>
      1.88.6
      <small>
        (2022-03-18)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        На странице пользователя Twitter теперь можно искать по сообщениям
      </li>
      <li>
        При поиске пользователей Twitter можно указывать по каким полям искать
      </li>
      <li>
        Исправление ошибок в интерфейсе
      </li>
    </ul>
    <h4>
      1.88.5
      <small>
        (2022-03-16)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        В твитах добавлена ссылка на Twitter
      </li>
      <li>
        Статус отозванных ссылок корректно отображается в тасках WhatsApp
      </li>
      <li>
        Исправление ошибок в интерфейсе
      </li>
    </ul>
    <h4>
      1.88.4
      <small>
        (2022-02-28)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Улучшение интерфейса модуля Форумов
      </li>
      <li>
        Исправлен баг с ошибочным отображением модульного окна при переходе на не внешние ссылки
      </li>
      <li>
        Исправлена ошибка с отображением номера телефона у Telegram канала в глобальном поиска
      </li>
      <li>
        Исправление ошибок в интерфейсе
      </li>
    </ul>
    <h4>
      1.88.3
      <small>
        (2022-01-31)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Улучшение интерфейса модуля Форумов
      </li>
      <li>
        Улучшение производительности модуля Twitter
      </li>
      <li>
        Улучшение производительности модуля Whatsapp
      </li>
      <li>
        Исправление ошибок в интерфейсе
      </li>
    </ul>
    <h4>
      1.88.2
      <small>
        (2022-01-16)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Исправлена ошибка с неотображением форумных сообщений на странице с темой
      </li>
      <li>
        Улучшение производительности модуля форумов
      </li>
      <li>
        Исправление ошибок в интерфейсе
      </li>
    </ul>
    <h4>
      1.88.1
      <small>
        (2021-12-21)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Сообщения Hoop теперь корректно отображают ссылку на чат
      </li>
      <li>
        Изображения Hoop имеют те же элементы управления что и изображения в других модулях
      </li>
      <li>
        Первый уровень тем в форумах теперь раскрывается автоматически
      </li>
      <li>
        Исправление ошибок в интерфейсе
      </li>
    </ul>
    <h4>
      1.88.0
      <small>
        (2021-12-10)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлена поддержка коротких Telegram Joinlink'ов (t.me/+AAA...)
      </li>
    </ul>
    <h4>
      1.87.1
      <small>
        (2021-12-07)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Исправление ошибок в интерфейсе
      </li>
    </ul>
    <h4>
      1.87.0
      <small>
        (2021-12-02)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Интерфейс списка каналов Hoop'а был переработан для большей унификации с другими модулями
      </li>
      <li>
        В модуле Telegram теперь можно назначать таски конкретным ботам
      </li>
    </ul>
    <h4>
      1.86.1
      <small>
        (2021-11-25)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Hoop: отображается специальная пометка для аккаунтов алиасов
      </li>
      <li>
        Множественное исправление ошибок
      </li>
    </ul>
    <h4>
      1.86.0
      <small>
        (2021-11-24)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлен модуль мессенджера Hoop
      </li>
      <li>
        При экспорте Telegram сообщений раскрываются ссылки
      </li>
      <li>
        Добавлено поле "Дата создания" для групп и каналов Telegram
      </li>
      <li>
        Для групп и каналов Telegram отображается флаг - Проверенно/мошенничество
      </li>
      <li>
        Множественное исправление ошибок
      </li>
    </ul>
    <h4>
      1.85.3
      <small>
        (2021-09-30)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлено поле "Последняя проверка" на страницу пользователя Telegram
      </li>
    </ul>
    <h4>
      1.85.2
      <small>
        (2021-09-13)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Множественное исправление ошибок
      </li>
    </ul>
    <h4>
      1.85.1
      <small>
        (2021-09-10)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлен экспорт сообщений и пользователей из модуля Twitter
      </li>
    </ul>
    <h4>
      1.85.0
      <small>
        (2021-08-23)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Доступно форматирование для Telegram сообщений
      </li>
      <li>
        Исправлены проблемы и оптимизировано проигрывание видео
      </li>
      <li>
        Исправлена проблема с сетевыми графами
      </li>
      <li>
        Множественное исправление ошибок
      </li>
    </ul>
    <h4>
      1.84.2
      <small>
        (2021-07-21)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Исправлена ошибка с лимита экспорта
      </li>
      <li>
        Улучшение интерфейса модуля форумов
      </li>
    </ul>
    <h4>
      1.84.1
      <small>
        (2021-07-07)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Множественное исправление ошибок
      </li>
    </ul>
    <h4>
      1.84.0
      <small>
        (2021-06-30)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Улучшение в скорости загрузки интерфейса в модуле Telegram
      </li>
      <li>
        Добавлена поддержка анимированных стикеров в модуле Telegram
      </li>
      <li>
        ID каналов/чатов в списке теперь кликабельные, по аналогии со списком юзеров
      </li>
      <li>
        Множественное исправление ошибок
      </li>
    </ul>
    <h4>
      1.83.2
      <small>
        (2021-06-18)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлен перевод имен пользователей Telegram
      </li>
      <li>
        Улучшения UX списка задач Telegram
      </li>
      <li>
        Граф ответов в Telegram теперь отображает аватары пользователей и групп, также как это делает граф кейсов
      </li>
      <li>
        Исправлена ошибка с переходом на внешние ссылки в Safari
      </li>
      <li>
        Множественное исправление ошибок
      </li>
    </ul>
    <h4>
      1.83.1
      <small>
        (2021-06-11)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Подсказки о области поиска в различных модулях
      </li>
      <li>
        Информация о лимитах экспорта сообщений
      </li>
      <li>
        Указатель на канал/чат в списке задач Telegram
      </li>
      <li>
        Вкладка "Известные ссылки" в чатах Telegram, отображающая известные join-ссылки по этому чату.
      </li>
      <li>
        Исправлена ошибка с неотображением списка источника при поиске по имени в глобальном поиске
      </li>
      <li>
        Множественное исправление ошибок
      </li>
    </ul>
    <h4>
      1.83.0
      <small>
        (2021-05-27)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлен экспорт для результатов отложенного поиска по Telegram
      </li>
      <li>
        При экспорте пользователей Telegram, теперь можно сразу же выгружать их вместе с телефонными номерами
      </li>
      <li>
        При экспорте сообщений Telegram, теперь можно выбрать опцию для добавления в экспорт перевода
      </li>
      <li>
        Множественное исправление ошибок.
      </li>
    </ul>
    <h4>
      1.82.3
      <small>
        (2021-04-24)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Возможность принудительного обновления ошибочных результатов глобального поиска
      </li>
      <li>
        Множественное исправление ошибок.
      </li>
    </ul>
    <h4>
      1.82.2
      <small>
        (2021-03-31)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Новые запросы в реальном времени (по номеру телефона): Botim
      </li>
      <li>
        Новые запросы в реальном времени (по юзернейму): Gab.com, Letterboxd.com
      </li>
      <li>
        Множественное исправление ошибок.
      </li>
    </ul>
    <h4>
      1.82.1
      <small>
        (2021-03-16)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Исправления модуля Threema (совместимость с новым API)
      </li>
    </ul>
    <h4>
      1.82.0
      <small>
        (2021-02-27)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Возможность делать запланированные автопоиски каналов и чатов по интересующим тематикам, для улучшения покрытия.
      </li>
      <li>
        Страница статуса последних обновлений веб источников
      </li>
      <li>
        Страница для лукапов по Facebook
      </li>
      <li>
        Множественное исправление ошибок.
      </li>
    </ul>
    <h4>
      1.81.4
      <small>
        (2021-02-15)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Исправлена проблема с неверным отображением времени, в случае временной зоны отличной от UTC
      </li>
      <li>
        Множественное исправление ошибок.
      </li>
    </ul>
    <h4>
      1.81.3
      <small>
        (2021-02-12)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлена возможность использовать автодополнение при поиске в лентах. Для использования включите опцию “ВВключить автозаполнение поиска в ленте проекта” в настройках
      </li>
      <li>
        Добавлена возможно выделять Telegram сообщения для экспорта. Для использования включите опцию “Включить выделение сообщения для экспорта” в настройках
      </li>
      <li>
        Множественное исправление ошибок.
      </li>
    </ul>
    <h4>
      1.81.2
      <small>
        (2021-01-23)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Доработки UI лент в кейсе
      </li>
      <li>
        Улучшенный lightbox для просмотра изображений
      </li>
      <li>
        Новые запросы в реальном времени (по юзернейму): Calendly, Minds.com
      </li>
    </ul>
    <h4>
      1.81.1
      <small>
        (2021-01-14)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлена поддержка нового типа join ссылок Telegram'а
      </li>
      <li>
        Новые запросы в реальном времени (по юзернейму):  Mewe Group Search, Lbry.tv
      </li>
      <li>
        Проверка существования аккаунта в Signal по номеру телефона
      </li>
      <li>
        Множественное исправление ошибок.
      </li>
    </ul>
    <h4>
      1.81.0
      <small>
        (2020-12-31)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлен поиск похожих сообщений в Telegram
      </li>
      <li>
        Новые запросы в реальном времени (по юзернейму): KWork
      </li>
    </ul>
    <h4>
      1.80.0
      <small>
        (2020-12-25)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        В Telegram'е можно добавлять Гео-кластер задачи.
      </li>
      <li>
        Данные по реплаям пользователя (Telegram) могут быть экспортированы
      </li>
      <li>
        Новые запросы в реальном времени (по юзернейму): Codewars
      </li>
    </ul>
    <h4>
      1.79.6
      <small>
        (2020-12-18)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        В экспорт чатов добавлено поле username
      </li>
      <li>
        Запросы о использование токенов теперь можно отключить.
      </li>
      <li>
        Множественное исправление ошибок.
      </li>
    </ul>
    <h4>
      1.79.5
      <small>
        (2020-12-11)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Множественное исправление ошибок.
      </li>
    </ul>
    <h4>
      1.79.4
      <small>
        (2020-12-01)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Новые запросы в реальном времени (по юзернейму): Teletype.in, Tinder
      </li>
      <li>
        Новые запросы в реальном времени (по телефонному номеру): MyWorld@Mail.ru
      </li>
    </ul>
    <h4>
      1.79.3
      <small>
        (2020-11-24)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Новые запросы в реальном времени (по юзернейму): OnlyFans, Kinopoisk, Steam, Faceit, Mixcloud
      </li>
      <li>
        You can now add notes to the selected case by pressing the Notes button on the bottom right of the screen (only shown when a case is selected)
      </li>
      <li>
        Notes added to profiles: you can now leave separate notes for every profile in your case
      </li>
      <li>
        Profile change history is available
      </li>
      <li>
        Multiple UI changes in profiles
      </li>
      <li>
        В поиск групп в Telegram'е теперь можно задавать настройки экстракции join ссылки
      </li>
      <li>
        В профиле пользователя твиттер теперь выводится свойство вебсайт и локация указанная в профиле.
      </li>
      <li>
        Множественное исправление ошибок.
      </li>
    </ul>
    <h4>
      1.79.2
      <small>
        (2020-10-21)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Новые запросы в реальном времени (по юзернейму): EroProfile, Wordpress
      </li>
      <li>
        Новые запросы в реальном времени (по домену): Account extractor
      </li>
      <li>
        Глобальный поиск по имени теперь нормально работает с Telegram'омм
      </li>
      <li>
        В экпорт сообщений Telegram'а добавлена ссылка на исходное сообщение (если доступна)
      </li>
      <li>
        Добавлен экспорт в txt
      </li>
      <li>
        Множественное исправление ошибок.
      </li>
    </ul>
    <h4>
      1.79.1
      <small>
        (2020-10-08)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Новые запросы в реальном времени (по юзернейму): Flipboard, Spotify, Angel.co, Soundcloud, ProductHunt, Fiverr
      </li>
      <li>
        Оптимизиация UI для планшетов.
      </li>
      <li>
        Множественное исправление ошибок.
      </li>
    </ul>
    <h4>
      1.79
      <small>
        (2020-09-30)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Для пользователей Telegram доступна экстракция сущностей
      </li>
      <li>
        Новые запросы в реальном времени (по юзернейму): Kickstarter, Dribbble, Vimeo
      </li>
      <li>
        При обновление фида теперь присутствует разделитель отделяющий старые сообщения от новых
      </li>
      <li>
        В списке профилей можно выбрать количество профилей на странице
      </li>
      <li>
        Улучшен поиск по профилям. Теперь поиск ищет по все полям.
      </li>
      <li>
        Изменение расположения интерфейсных элементов. Меню с выбором источников теперь сворачивается
      </li>
      <li>
        Улучшение загрузки больших графов в проектах
      </li>
      <li>
        Новые алгоритмы укладки графов в проектах
      </li>
      <li>
        Множественное исправление ошибок.
      </li>
    </ul>
    <h4>
      1.78.1
      <small>
        (2020-08-30)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Исправления Гео задач Twitter'а
      </li>
      <li>
        Исправления множественных операций в задачах Telegram
      </li>
      <li>
        Исправления Ридера в Проектах
      </li>
      <li>
        Веб источники могут быть просмотрены из списка источников
      </li>
      <li>
        Исправления в Сетевом графе проекта
      </li>
      <li>
        Информация о том когда обновлялся источник доступна в списке веб источников
      </li>
    </ul>
    <h4>
      1.78
      <small>
        (2020-08-19)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Веб источники можно добавлять через специальную форму.
      </li>
      <li>
        Добавлен экспорт новостей из веб-ридера.
      </li>
      <li>
        Добавлена возможность просмотреть результаты твиттер гео тасков.
      </li>
      <li>
        Множественное исправление ошибок.
      </li>
    </ul>
    <h4>
      1.77
      <small>
        (2020-08-11)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Задачи реального времения для Twitter'а
      </li>
      <li>
        Гео задачи для Twitter
      </li>
      <li>
        Добавлена вкладка веб ридера в проекты: добавьте источники в проект для персонализированного фида по статьям
      </li>
      <li>
        Посты в вебленте можно сортировать по дате обнаружения
      </li>
      <li>
        Добавлена возможность создания и дополнения профилей по результатам глобального поиска
      </li>
      <li>
        Исправлено отображение результатов срабатывания триггеров для Twitter/WhatsApp/Telegram
      </li>
      <li>
        Кнопка Синхронизировать теперь отображается только в при наличии join-ссылки или username'а
      </li>
      <li>
        В Telegram ленте в кейсе доступна кнопка обновления ленты при появление новых сообщений
      </li>
    </ul>
    <h4>
      1.76
      <small>
        (2020-07-28)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Подсветка результатов поиск при множественных словах теперь работает нормально
      </li>
      <li>
        В окно редактирования кейса добавили возможность управлять тегами используемыми для автодополнения.
      </li>
      <li>
        В списке тасков Telegram'а теперь отображается ссылка на пользователя который создал joinlink (если эти данные возможно получить)
      </li>
      <li>
        Триггеры теперь срабатывают и на контент полученный из Twitter'а
      </li>
      <li>
        Название канала в отчетах теперь выделяется жирным
      </li>
    </ul>
    <h4>
      1.75
      <small>
        (2020-07-23)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Объединенная лента в проектах: добавлены возможности отметить/убрать отметку со всех источников, проводить массовые операции на найденных сообщениях
      </li>
      <li>
        Добавлены детальные страницы для объявлений
      </li>
      <li>
        Добавлена возможность анализа соотношения стран в чате: кликните на страну чтобы убрать ее из графика.
      </li>
      <li>
        Добавлена возможность создавать продвинутые триггеры
      </li>
      <li>
        Новые запросы в реальном времени: Twitter (поиск по номеру телефона и по адресу электронной почты),  Foursquare (поиск по номеру телефона и по адресу электронной почты), Facebook (поиск по электронной почте), Instagram (поиск по электронной почте), Bookmate (поиск по электронной почте), GoodReads (поиск по электронной почте), Strava (поиск по электронной почте),  SportTracks (поиск по электронной почте), Duolingo (поиск по электронной почте), Adidas (поиск по электронной почте), Nike (поиск по электронной почте), Pinterest (поиск по электронной почте), Tumblr (поиск по электронной почте), Vivino (поиск по имени пользователя и электронной почте)
      </li>
    </ul>
    <h4>
      1.74
      <small>
        (2020-07-08)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Совмещенный Telegram фид добавлен в проекты
      </li>
      <li>
        Утекшие пароли используют новый бекенд
      </li>
      <li>
        Множественные исправления
      </li>
    </ul>
    <h4>
      1.73
      <small>
        (2020-07-05)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Для Telegram каналов с медией и извлеченными сущностями, доступны извлеченные с изображений лица.
      </li>
      <li>
        Множественные доработки процедура авторизации и безопасности
      </li>
      <li>
        Изменена дефолтная сортировка Вебридера
      </li>
    </ul>
    <h4>
      1.72
      <small>
        (2020-07-02)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Для каналов/чатов Telegram'а с извлеченными сущностями, доступны графики самых используемых слов
      </li>
      <li>
        Исправлены проблемы с автологаутом в отдельных случаях
      </li>
    </ul>
    <h4>
      1.71
      <small>
        (2020-07-01)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        В списке сообщений Telegram теперь можно переходить на любую страницу
      </li>
      <li>
        Даты в экспорте теперь выгружаются во временной зоне выбранной в настройках
      </li>
      <li>
        Парольная политика обновлена
      </li>
      <li>
        Добавлен автологаут при неактивности через 30 минут
      </li>
    </ul>
    <h4>
      1.70
      <small>
        (2020-06-29)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Оптимизация инфраструктуры.
      </li>
      <li>
        Извлечения ключевых слов и именованных сущностей для 6 языков (en, it, fr, zh, de, ru).
      </li>
      <li>
        Добавлена вкладка "Новые пользователи" в Telegram'е для определенных групп пользователей.
      </li>
      <li>
        Новые источники поиска: 9gag (Поиск по имени пользователя), F3(Поиск по имени пользователя), Patreon (Поиск по имени пользователя и идентификатору пользователя), Yandex Messenger (Поиск по номеру телефона).
      </li>
      <li>
        Оптимизация графиков.
      </li>
      <li>
        При анализе изображений теперь применяется также OCR.
      </li>
      <li>
        Добавлено поле "Описание" для мониторинга статуса по номеру телефона.
      </li>
      <li>
        Добавлена подсветка ключевого слова про поиске.
      </li>
      <li>
        Добавлено автодополнение типов кошельков и тегов профилей.
      </li>
      <li>
        Добавлена кнопка "Перевести" в сообщения и товары в разделе Форумы.
      </li>
      <li>
        Исправлены некоторые ошибки.
      </li>
    </ul>
    <h4>
      1.60
      <small>
        (2020-05-13)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Отчеты по работе триггеров теперь можно выгружать в PDF
      </li>
    </ul>
    <h4>
      1.59
      <small>
        (2020-05-11)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Доработки механизмов триггеров.
      </li>
      <li>
        Доработки профилей в проектах
      </li>
      <li>
        К результатам глобального поиска добавлен экспорт
      </li>
      <li>
        Новые запросы в реальном времени: LeetCode, Boosty, CodeMentor
      </li>
    </ul>
    <h4>
      1.58.5
      <small>
        (2020-04-20)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Новые запросы в реальном времени: Google Hangouts (номер телефона, электронная почта), OK (номер телефона)
      </li>
      <li>
        Удалены запросы в реальном времени по мессенджеру Tango
      </li>
      <li>
        Исправления в работе триггеров
      </li>
    </ul>
    <h4>
      1.58.4
      <small>
        (2020-04-12)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Задачи Телеграм: Пакетные действия
      </li>
      <li>
        Новые запросы в реальном времени: MyWorld@Mail.ru (электронная почта, имя пользователя), ICQ (номер телефона), Tango Messenger (номер телефона, электронная почта), Dev.to (имя пользователя), HackerRank (имя пользователя) , Moddb (имя пользователя), TravelBlog (имя пользователя)
      </li>
      <li>
        Профайлер теперь делает предложения, основанные на псевдонимах почтовых провайдеров и предыдущих записях
      </li>
      <li>
        Добавлены новые базы данных: Gravatar (20M+ записей), StackOverflow (4M+ записей), YouNow (43M+ записей)
      </li>
      <li>
        Online status monitoring performance improvements
      </li>
    </ul>
    <h4>
      1.58.3
      <small>
        (2020-03-28)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Данные карт теперь запрашиваются с собственного тайл-сервера для большей безопасности.
      </li>
      <li>
        Глобальный поиск: Изменения UI
      </li>
    </ul>
    <h4>
      1.58.2
      <small>
        (2020-03-14)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Фильтр сообщений пользователей по чатам
      </li>
      <li>
        Telegram: на карте теперь можно отображать пользователей рядом
      </li>
      <li>
        Глобальный поиск: теперь в сайдбаре отображается больше информации по карточке
      </li>
      <li>
        Сущности полученные из чатов теперь отображаются в одном формате
      </li>
    </ul>
    <h4>
      1.58.1
      <small>
        (2020-03-08)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Селекты с большим количеством опций заменены на выпадающие списки
      </li>
      <li>
        Добавлен фильтр групп Whatsapp по стране владельца группы
      </li>
      <li>
        Добавлена возможность перевода названий и описаний групп/чатов/каналов
      </li>
    </ul>
    <h4>
      1.58
      <small>
        (2020-03-06)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Список задач WhatsApp теперь поддерживает опции "Высокий приоритет" и "Сбор медиа"
      </li>
      <li>
        Новые запросы в реальном времени: IMO (поиск по номеру телефона)
      </li>
      <li>
        Добавлены задачи в модуль Twitter
      </li>
      <li>
        Добавлен селектор временной зоны в настройках
      </li>
      <li>
        Добавлена карта пользователей Telegram в реальном времени
      </li>
      <li>
        AДобавлена карта истории геолокаций пользователя
      </li>
      <li>
        Добавлены метаданные для некоторых групп WhatsApp
      </li>
      <li>
        Кнопка присоединения к группе WhatsApp
      </li>
    </ul>
    <h4>
      1.57
      <small>
        (2020-02-22)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <!-- <li>Initial implementation of new Tgcp "People Nearby" function</li> -->
      <li>
        Telegram: улучшены фильтры поиска групп
      </li>
      <!-- <li>Tgcp: Added category filters to chats list</li> -->
      <li>
        Добавлена возможность экспортировать полученные из чата сущности
      </li>
      <li>
        Новые запросы в реальном времени: VK (поиск по электронной почте и номеру телефона)
      </li>
    </ul>
    <h4>
      1.56
      <small>
        (2020-02-19)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлена статистика резолвов номеров телефона
      </li>
      <li>
        Telegram: добавлена опция пермутации поиска по группам
      </li>
      <li>
        Telegram: Добавлены фильтры типов (chat/channel), страны и языка для групповых чатов
      </li>
      <li>
        Новые запросы в реальном времени: Kaskus.com (поиск по имени пользователя, номеру телефона и электронной почте)
      </li>
    </ul>
    <h4>
      1.55
      <small>
        (2020-02-16)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Обновлена секция сущностей
      </li>
      <li>
        Добавлены графики для мониторинга номеров
      </li>
      <li>
        Новые запросы в реальном времени: Trello.com (поиск по электронной почте и номеру телефона), Tripadvisor.com (поиск по имени пользователя), Skype (поиск по имени пользователя, электронной почте и номеру телефона)
      </li>
    </ul>
    <h4>
      1.54
      <small>
        (2020-02-12)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлена вкладка сущностей в группах WhatsApp
      </li>
      <li>
        Мониторинг статуса онлайн по номеру телефона добавлена во вкладку инструменты OSINT
      </li>
      <li>
        Новые запросы в реальном времени: LinkedIn.com
      </li>
    </ul>
    <h4>
      1.53
      <small>
        (2020-02-10)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлен график по странам пользователей в чатах Telegram
      </li>
      <li>
        Добавлен экспорт в .csv в поиске групп Telegram в модуле задач
      </li>
    </ul>
    <h4>
      1.52
      <small>
        (2020-02-09)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлены фильтры по странам в модуле WhatsApp
      </li>
      <li>
        Добавлен поиск групповых чатов в Telegram в модуле задач
      </li>
    </ul>
    <h4>
      1.51
      <small>
        (2020-02-06)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлен мониторинг активности пользователя по номеру телефона
      </li>
      <li>
        График пользователей по странам в модуле Whatsapp
      </li>
    </ul>
    <h4>
      1.50.1
      <small>
        (2020-01-29)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Исправления ошибок
      </li>
    </ul>
    <h4>
      1.50
      <small>
        (2020-01-28)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Список задач для модуля WhatsApp
      </li>
    </ul>
    <h4>
      1.49.2
      <small>
        (2020-01-27)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлена вкладка Аккаунты для поддерживаемых модулей
      </li>
      <li>
        Добавлен фильтр активности баз данных организаций
      </li>
    </ul>
    <h4>
      1.49.1
      <small>
        (2020-01-27)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Исправления ошибок в модуле WhatsApp
      </li>
    </ul>
    <h4>
      1.49
      <small>
        (2020-01-26)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Исправлены запросы в реальном времени по номеру телефона в Instagram
      </li>
      <li>
        Теперь при использовании глобального поиска отображается результат запросов по каждому модулю
      </li>
      <li>
        Добавлен раздел WhatsApp, теперь вы можете добавить задачу для парсинга групповых чатов WhatsApp в пользовательском интерфейсе.
      </li>
    </ul>
    <h4>
      1.48
      <small>
        (2020-01-22)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Новые запросы в реальном времени: Viber
      </li>
    </ul>
    <h4>
      1.47
      <small>
        (2020-01-21)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлены базы данных организаций
      </li>
      <li>
        Новые запросы в реальном времени: zen.yandex.com
      </li>
    </ul>
    <h4>
      1.46
      <small>
        (2020-01-15)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Обновлен интерфейс форумов
      </li>
      <li>
        Теперь возможно получить номер телефона даже тех профилей Telegram, в которых нет никакой информации кроме TGID
      </li>
      <li>
        Кнопка получения номера телефона теперь отображается и на карточках в поиске
      </li>
      <li>
        Новые запросы в реальном времени: Buzzfeed.com
      </li>
    </ul>
    <h4>
      1.45
      <small>
        (2020-01-10)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Новые запросы в реальном времени: Gitee.com, Twitch.tv, YouNow.com, Periscope.tv, Gumroad.com, Eyeem.com, Coderwall.com
      </li>
      <li>
        Исправлено отображение репостов в Telegram
      </li>
    </ul>
    <h4>
      1.44
      <small>
        (2020-01-08)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлена секция хэштегов в Twitter
      </li>
      <li>
        Новые запросы в реальном времени: BitBucket.org, Gitlab.com
      </li>
      <li>
        Улучшено получение электронных почт по профилям Github
      </li>
      <li>
        Расширена информация получаемая при обработке изображений
      </li>
    </ul>
    <h4>
      1.43.1
      <small>
        (2019-12-18)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Пакетная обработка для обогащения данных
      </li>
    </ul>
    <h4>
      1.43
      <small>
        (2019-12-15)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлены инструменты получения IMSI и геолокации по номеру телефона (в зависимости от наличия в некоторых странах, обратитесь к поддерживаемым MNO и странам)
      </li>
      <li>
        Новые запросы в реальном времени: Tiktok, Keybase.io, GetPocket.com
      </li>
    </ul>
    <h4>
      1.42
      <small>
        (2019-12-10)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Исправления ошибок
      </li>
    </ul>
    <h4>
      1.41
      <small>
        (2019-12-09)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлена кнопка для создания профиля из учетных записей Telegram
      </li>
    </ul>
    <h4>
      1.40.1
      <small>
        (2019-12-05)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Исправления ошибок
      </li>
    </ul>
    <h4>
      1.40
      <small>
        (2019-12-04)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлены триггеры
      </li>
    </ul>
    <h4>
      1.39
      <small>
        (2019-12-03)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Новые доски объявлений: Kiliks.com, My.adswan.com
      </li>
    </ul>
    <h4>
      1.38
      <small>
        (2019-12-02)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлен модуль мониторинга новостей
      </li>
      <li>
        Добавлен поиск по объявлениям
      </li>
    </ul>
    <h4>
      1.37
      <small>
        (2019-11-29)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлено отображение исторических данных Telegram
      </li>
      <li>
        Telegram: Channels and Users geo data is shown
      </li>
      <li>
        Profiles merging
      </li>
      <li>
        Новые доски объявлений: Casa.it, Wikicasa.it, Idealista.it, Bakeca.it, Olx.kz
      </li>
      <li>
        Поиск по утечкам теперь можно осуществлять с помощью регулярных выражений или подстановочных знаков
      </li>
    </ul>
    <h4>
      1.36
      <small>
        (2019-11-19)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Telegram: Вы можете добавить бота в группу для парсинга
      </li>
      <li>
        Добавлен новый инструмент доступа. <router-link :to="{ name:'ip_msisdn_detect'}">
          MSISDN инструмент обнаружения фишинга
        </router-link>.
      </li>
      <li>
        Получение номеров телефона из Facebook
      </li>
    </ul>
    <h4>
      1.35
      <small>
        (2019-11-18)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Автоматический профайлер теперь предлагает информацию на основе оценки релевантности
      </li>
    </ul>
    <h4>
      1.34
      <small>
        (2019-11-14)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Новый поиск в реальном времени <router-link :to="{path:'/'}">
          Поиск твитов по геолокации
        </router-link>
      </li>
      <li>
        Новые доски объявлений: AutoScout24.fr,ParuVendu.fr, Ouestfrance-immo.com, LebonCoin, Annonces du bateau,Automobile.fr,Lacentrale.fr ,Auto-occasion.fr,Iimmoboulevard.com, dorus.ru, Kupiprodai.ru, Rydo.ru
      </li>
      <li>
        Добавлена фильтрация по источнику утечек информации
      </li>
      <li>
        Теперь при обновлении профилей пользователь получит уведомление
      </li>
      <li>
        Ускорен поиск по доскам объявлений
      </li>
      <li>
        Telegram: добавлена возможность задавать период синхронизации задач
      </li>
    </ul>
    <h4>
      1.33
      <small>
        (2019-11-07)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Исправлен экспорт сетевых графиков
      </li>
      <li>
        Добавлен график активности пользователей чатов Telegram
      </li>
      <li>
        Новые запросы в реальном времени: WhatsApp
      </li>
      <li>
        Telegram: добавлен массовый импорт гео задач
      </li>
    </ul>
    <h4>
      1.32.1
      <small>
        (2019-10-29)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Исправления ошибок
      </li>
    </ul>
    <h4>
      1.32
      <small>
        (2019-10-29)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлены форумы Даркнет
      </li>
      <li>
        Добавлена генерация pdf отчетов по проектам/профилям
      </li>
      <li>
        Новые запросы в реальном времени: Ebay, Ask.fm
      </li>
      <li>
        Оптимизация производительности
      </li>
      <li>
        Telegram: добавлена фильтрация по стране пользователя (по префиксу номера телефона)
      </li>
    </ul>
    <h4>
      1.31
      <small>
        (2019-10-19)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Исправлен поиск по хэшам паролей в модуле утечек данных
      </li>
      <li>
        Новые запросы в реальном времени: Medium, Badoo, Patreon
      </li>
      <li>
        Задачи Telegram теперь можно определять к проекту
      </li>
    </ul>
    <h4>
      1.30
      <small>
        (2019-10-15)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Zalo: добавлена страница детальной информации пользователей
      </li>
      <li>
        Улучшение производительности в модуле задач Telegram
      </li>
      <li>
        Улучшение производительности карт
      </li>
      <li>
        При массовом добавлении задач Telegram вы можете также выбрать параметры для их обработки
      </li>
      <li>
        Исправлена ошибка при поиске невалидных номеров телефона
      </li>
      <li>
        Исправлена обработка ошибок от API утечек
      </li>
      <li>
        Исправлены ошибки с проектами по умолчанию
      </li>
      <li>
        Добавлено поле кошельки к профилям
      </li>
      <li>
        Исправлено подтверждение полей профиля
      </li>
      <li>
        Исправлены ошибки в поиске Threema
      </li>
    </ul>
    <h4>
      1.29
      <small>
        (2019-10-10)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлено покрытие вышками сотовой связи на глобальную карту
      </li>
      <li>
        Добавлено отображение на карте всех гео задач добавленных пользователем
      </li>
      <li>
        Исправление поиска пользователей с '@'
      </li>
      <li>
        Кнопка Синхронизировать теперь не отображается во время синхронизации задачи
      </li>
      <li>
        Графики в детальном отображении данных теперь могут быть отфильтрованы по дате
      </li>
      <li>
        Добавлена опция сбора медиа в задачах
      </li>
      <li>
        Улучшение поиска профилей и добавление фильтров поиска
      </li>
    </ul>
    <h4>
      1.28.1
      <small>
        (2019-10-08)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Изменено поведение получения номера Telegram
      </li>
      <li>
        Исправления прокручивания страниц в меню
      </li>
      <li>
        Добавлена кнопка перевода ответов на сообщения
      </li>
      <li>
        Маркеры групп Telegram на карте теперь отображают количество пользователей в чате
      </li>
    </ul>
    <h4>
      1.28
      <small>
        (2019-10-07)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Поиск по номеру телефона в Facebook/VK/Instagram добавлен в глобальный поиск
      </li>
      <li>
        Чаты Telegram с именем пользователя теперь отображают кнопку Синхронизировать в детальном отображении
      </li>
      <li>
        Добавлена статистика номеров телефона в утечках информации
      </li>
      <li>
        Новые доски объявлений: adswan.com, us.claseek.com, id.claseek.com, sg.claseek.com,
        hk.claseek.com, ph.claseek.com, au.claseek.com, in.claseek.com, my.claseek.com
      </li>
    </ul>
    <h4>
      1.27
      <small>
        (2019-10-06)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Кластеризация групп Telegram теперь зависит от количества пользователей в чатах
      </li>
      <li>
        Добавлено отображение бывших пользователей и администраторов чатов Telegram
      </li>
      <li>
        В детальном отображении профиля пользователя Telegram теперь отображаются чаты, в которых пользователь был администратором
      </li>
      <li>
        Содержимое вкладок в разделах Мессенджеры / Социальные сети теперь загружается по требованию
      </li>
      <li>
        Новые доски объявлений: subito.it, vivastreet.it, kijiji.it, immobilare.it
      </li>
      <li>
        Добавлено тэгирование профилей в проектах для улучшения фильтрации
      </li>
    </ul>
    <h4>
      1.26
      <small>
        (2019-10-02)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Кнопка Синхронизировать в чатах Telegram теперь активирует неактивные задачи
      </li>
      <li>
        Добавлена глобальная карта
      </li>
      <li>
        Добавлен полнотекстовый поиск в Twitter
      </li>
      <li>
        Добавлена кнопка перевода сообщений и твитов
      </li>
    </ul>
    <h4>
      1.25
      <small>
        (2019-10-01)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Исправлены незначительные ошибки
      </li>
      <li>
        Исправлен поиск Threema
      </li>
      <li>
        Новые запросы в реальном времени: Telegram, VK
      </li>
      <li>
        Профили теперь можно найти в глобальном поиске
      </li>
      <li>
        Профили теперь можно создавать в глобальном поиске при нажатии на кнопку Создать профиль
      </li>
      <li>
        Автоматическое добавление мессенджеров к соответствующим профилям
      </li>
      <li>
        Ускорена синхронизация задач Telegram
      </li>
    </ul>
    <h4>
      1.24
      <small>
        (2019-09-24)
      </small>
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Новые запросы в реальном времени: Imgur, Disqus, SlideShare, Venmo
      </li>
      <li>
        Исправлены проблемы с кэшированием списка проектов
      </li>
      <li>
        Добавлена возможность коллаборации в проектах
      </li>
      <li>
        Исправлена проблема с кэшированием страницы
      </li>
    </ul>
    <h4>
      1.23
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Новые запросы в реальном времени: Tumblr, Etsy, Reddit
      </li>
      <li>
        Исправлено отображение сохраненных объектов
      </li>
      <li>
        Исправлен поиск утечек информации по IP адресам
      </li>
      <li>
        Результаты поиск в модуле утечек информации теперь можно экспортировать в csv
      </li>
    </ul>
    <h4>
      1.22
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Новые запросы в реальном времени: multiple address book lookups, Behance, Pinterest
      </li>
      <li>
        В профилях теперь выставляются первые доступные имя и аватар
      </li>
      <li>
        Селектор гео задач Telegram теперь отображает площадь покрытия
      </li>
    </ul>
    <h4>
      1.21
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Исправления проблем с кэшированием
      </li>
      <li>
        Добавлен поиск по Zalo
      </li>
      <li>
        Добавлен список пользователей Zalo
      </li>
      <li>
        Профили теперь обновляются без необходимости обновлять страницу
      </li>
      <li>
        Исправлена ошибка получения вьетнамских номеров
      </li>
    </ul>
    <h4>
      1.20
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Изменения в графиках профилей Twitter
      </li>
      <li>
        Исправлено отображения детальной информации профилей Twitter
      </li>
      <li>
        Исправлены запросы в реальном времени для поиска по Twitter
      </li>
      <li>
        Обработчики Threema теперь отправляют запросы через новый сервер
      </li>
      <li>
        Новые запросы в реальном времени: about.me, 500px
      </li>
      <li>
        Пароли в утечках информации теперь отображаются в карточном виде
      </li>
      <li>
        Добавлено поле поиска по всем компонентам карты
      </li>
      <li>
        Изменения UI
      </li>
    </ul>
    <h4>
      1.19
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлен список пользователей Twitter
      </li>
      <li>
        Все обработчики Twitter перенесены на новый Twitter API сервер
      </li>
      <li>
        Новые запросы в реальном времени: gfycat, pornhub
      </li>
      <li>
        Исправление проблем кэширования версий
      </li>
      <li>
        Изменения UI
      </li>
    </ul>
    <h4>
      1.18
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлено разделение задач в списке
      </li>
      <li>
        Добавлена кнопка "показать на карте" для гео задач
      </li>
      <li>
        Исправлена валидация некоторых локаций
      </li>
      <li>
        Изменения UI
      </li>
    </ul>
    <h4>
      1.17
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Поддержка гео задач Telegram
      </li>
      <li>
        Добавлен список изменений
      </li>
      <li>
        Исправлен редирект на страницу авторизации после выхода из системы
      </li>
      <li>
        Исправлены узлы Twitter в сетевых графиках проектов
      </li>
      <li>
        Исправлен поиск по длинным именам пользователей
      </li>
      <li>
        Исправлены кнопки показать больше/меньше на карточках
      </li>
      <li>
        Добавлены доски объявлений: silutesskelbimai.lt, ntbumas.lt, dirba.lt
      </li>
      <li>
        Fix task import for Telegram
      </li>
    </ul>
    <h4>
      1.16
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Исправлено обнаружение номера телефона для вьетнамских номеров
      </li>
      <li>
        Добавлен сетевой график для проектов
      </li>
      <li>
        Теперь утечки информации отображаются карточками в поиске
      </li>
      <li>
        Добавлен поиск утечек информации
      </li>
      <li>
        Добавлен сетевой график для проектов
      </li>
      <li>
        Добавлен поиск профилей GitHub по электронной почте
      </li>
      <li>
        Добавлен поиск по профилям Facebook
      </li>
      <li>
        Теперь используется новый API Threema
      </li>
      <li>
        Добавлена статистика Threema
      </li>
      <li>
        Добавлена возможность добавлять элементы сетевого графика в сохраненные объекты
      </li>
      <li>
        Добавлены доски объявлений: autogidas.lt, olx.com.pa, classified-ssingapore.expats.com,
        ekontaktas.lt, gumtree.sg, rinka.lt, singaporelocanto.sg
      </li>
    </ul>
    <h4>
      1.15
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Добавлены опции отображения сетевых графиков (BFS/Radial graphs)
      </li>
      <li>
        Теперь при нажатии на узлы сетевого графика детали отображаются в offcanvas
      </li>
      <li>
        Исправлена пагинация в списке профилей
      </li>
    </ul>
    <h4>
      1.14
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Новый путь для руководств оператора
      </li>
      <li>
        Исправления статистики утечек информации
      </li>
      <li>
        Исправлен поиск по Github
      </li>
      <li>
        Локализация статистика досок объявлений
      </li>
      <li>
        Добавлен поиск профилей Livejournal
      </li>
      <li>
        Исправлена пагинация в списке профилей
      </li>
      <li>
        Новые доски объявлений: sylhetdivision.bd, click.bd, cian.ru, domofond, khanslist
      </li>
    </ul>
    <h4>
      1.13
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Поддержка маркдаунов для заметок в проектах
      </li>
      <li>
        Добавлена кнопка получения HLR по номеру телефона в проектах
      </li>
    </ul>
    <h4>
      1.12
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Исправлена ошибка, при которой в некоторых браузерах невозможно было установить приоритет задач
      </li>
      <li>
        Добавлены профили в проекты
      </li>
      <li>
        Добавлена статистика по утечкам данных
      </li>
    </ul>
    <h4>
      1.11
    </h4>
    <ul class="uk-list uk-list-bullet">
      <li>
        Длинные описания на карточках теперь отображаются укороченными
      </li>
      <li>
        Добавлен поиск по профилям Flickr
      </li>
      <li>
        Исправлена экстракция данных для карточек поиска
      </li>
      <li>
        Сохранение пути пользователя после повторной авторизации
      </li>
    </ul>
  </div>
</template>
<script>export default {
  components: {}
}
</script>
